import React from 'react'
import { Carousel } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../../Header/Header';
import TopNavbar from '../TopNavbar'
import Footer from '../../Footer/Footer'
import banner10 from '../img/banner 10.png'
import f320 from '../img/f320.jpg'
import F1 from '../img/F1.jpg'
import f2 from '../img/f2.jpg'
import f3 from '../img/f3.jpg'

function F320() {
  return (
    <div>
    <Header />
    <TopNavbar/>

    <div className="banner-area">
    <Carousel id="bootcarousel" className="text-light heading-uppercase animate_text" controls indicators={false}>
      {/* <Carousel.Item className="bg-cover img-fluid active">
        <img src={banner10} className="d-block w-100" alt="First slide" />
      </Carousel.Item> */}
      <Carousel.Item className="bg-cover img-fluid" style={{padding:"30px"}}>
        <img src={f320} className="d-block w-100" alt="Second slide" />
      </Carousel.Item>
    </Carousel>
  </div>
<br/>
<section id="about-us" className="about-us">
      <Container data-aos="fade-up">
        <div className="section-title">
          <h2>DG TEAM - DISTRICT 320F</h2>
        </div><br/>
        <Row style={{padding:"100px"}}>
          <Col lg={4} md={6} className="d-flex align-items-stretch">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <img src={f2} style={{ width:'200px',height:'200px' }} alt="Kunduru Venkat Reddy" />
              </div>
              <h4>Kunduru Venkat Reddy</h4>
              <p style={{color:"black"}}>District Governor<br/><br />Mobile: 9866088699</p>
            </div>
          </Col>
          <Col lg={4} md={6} className="d-flex align-items-stretch">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <img src={f3} style={{ width:'200px',height:'200px' }} alt="Dr. K Chandra Shekar Arya" />
              </div>
              <h4>Dr. K Chandra Shekar Arya</h4>
              <p style={{color:"black"}}>First Vice District Governor<br/><br />Mobile: 9848073543</p>
            </div>
          </Col>
          <Col lg={4} md={6} className="d-flex align-items-stretch">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <img src={F1} style={{ width:'200px',height:'200px' }} alt="Sudhakar Reddy" />
              </div>
              <h4>N  Sudhakar Reddy</h4>
              <p style={{color:"black"}}>Second Vice District Governor<br/><br />Mobile: 9866221727</p>
            </div>
          </Col>
        </Row>

        {/* <div className="section-title">
          <h2>GALLERY - DISTRICT 320F</h2>
        </div> */}
        {/* <Row>
          <Col lg={4} md={6} className="d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <img src="https://lionsmd320.org/uploads/201216121217.jpg" style={{ width: '50%', cursor: 'zoom-in' }} alt="sarees" title="sarees" />
              </div>
              <span>sarees</span>
            </div>
          </Col>

       
        </Row> */}
      </Container>
    </section><br/>
    <Footer/> 
  </div>
  )
}

export default F320
