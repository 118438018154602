import React from 'react'
import { Carousel } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../../Header/Header';
import TopNavbar from '../TopNavbar'
import Footer from '../../Footer/Footer'
import banner10 from '../img/banner 10.png'
import c320 from '../img/c320.jpg'
import C1 from '../img/C1.jpg'
import c2 from '../img/c2.jpg'
import c3 from '../img/c3.jpg'

function C320() {
  return (
    <div>
    <Header />
    <TopNavbar/>

    <div className="banner-area">
    <Carousel id="bootcarousel" className="text-light heading-uppercase animate_text" controls indicators={false}>
      {/* <Carousel.Item className="bg-cover img-fluid active">
        <img src={banner10} className="d-block w-100" alt="First slide" />
      </Carousel.Item> */}
      <Carousel.Item className="bg-cover img-fluid"  style={{padding:"30px"}}>
        <img src={c320} className="d-block w-100" alt="Second slide" />
      </Carousel.Item>
    </Carousel>
  </div>
<br></br>
<section id="about-us" className="about-us">
      <Container data-aos="fade-up">
        <div className="section-title">
          <h2>DG TEAM - DISTRICT 320C</h2>
        </div><br></br>
        <Row style={{padding:"100px"}}>
          <Col lg={4} md={6} className="d-flex align-items-stretch">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <img src={c2} style={{ width:'200px',height:'200px' }} alt="Venkatnarayana Reddy" />
              </div>
              <h4>V. Krishna Prasad</h4>
              <p style={{color:"black"}}>District Governor<br/><br/>Mobile: 9966464557</p>
            </div>
          </Col>
          <Col lg={4} md={6} className="d-flex align-items-stretch">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <img src={c3} style={{ width:'200px',height:'200px' }} alt="K.Harish Reddy" />
              </div>
              <h4>k. Inna Reddy</h4>
              <p style={{color:"black"}}>First Vice District Governor<br/><br />Mobile: 9908634322</p>
            </div>
          </Col>
          <Col lg={4} md={6} className="d-flex align-items-stretch">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <img src={C1} style={{ width:'200px',height:'200px' }} alt="V. Krishna Prasad" />
              </div>
              <h4>Lion Kallen Krishna Reddy</h4>
              <p style={{color:"black"}}>Second Vice District Governor<br/><br />Mobile: 9849214959</p>
            </div>
          </Col>
        </Row>

        {/* <div className="section-title">
          <h2>GALLERY - DISTRICT 320C</h2>
        </div> */}
        {/* <Row>
          <Col lg={4} md={6} className="d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <img src="https://lionsmd320.org/uploads/221110101127.jpg" style={{ width: '50%', cursor: 'zoom-in' }} alt="test image" title="this is a test image" />
              </div>
              <span>this is a test image</span>
            </div>
          </Col>

          
        </Row> */}
      </Container>
    </section><br></br>
    <Footer/> 
  </div>
  )
}

export default C320
