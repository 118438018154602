import React from 'react';
import { Container, Nav, Navbar, NavDropdown, button } from 'react-bootstrap';
import './TopNavbar.css';
import logoImage from './img/logoImage.jpg';


function TopNavbar() {
  return (
    <div className="sticky-top" >
      <Navbar expand="lg "style={{backgroundColor:"#00569c"}} variant="light" >
        <Container >
        <img src={logoImage} alt="Description of the image" className="image" />
          <Navbar.Brand href="/">HOME</Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavDropdown title="ABOUT" id="about-dropdown" style={{backgroundColor:"#00569c"}}>
                <NavDropdown.Item href="international">INTERNATIONAL</NavDropdown.Item>
                <NavDropdown.Item href="multiple">MULTIPLE</NavDropdown.Item>
              </NavDropdown >
              <NavDropdown title="DISTRICTS" id="districts-dropdown" >
                <NavDropdown.Item href="320a">320A</NavDropdown.Item>
                <NavDropdown.Item href="320b">320B</NavDropdown.Item>
                <NavDropdown.Item href="320c">320C</NavDropdown.Item>
                <NavDropdown.Item href="320d">320D</NavDropdown.Item>
                <NavDropdown.Item href="320e">320E</NavDropdown.Item>
                <NavDropdown.Item href="320f">320F</NavDropdown.Item>
                <NavDropdown.Item href="320g">320G</NavDropdown.Item>
                <NavDropdown.Item href="320h">320H</NavDropdown.Item>
              </NavDropdown>
              <Nav.Link href="contact"><strong>CONTACT</strong></Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
        {/* <div className='col-xs-12 col-lg-2'>
          <Button type='button'  variant='primary'>Upload</Button>
        </div> */}
        <div className="attr-nav">
      
        <a href="upload"><button className="btn btn-warning">Upload</button></a>
        
      </div>
       </Navbar>
     </div>
   );
 }

 export default TopNavbar;



// import React from 'react';
// import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
// import logoImage from './img/logoImage.jpg';

// function TopNavbar() {
//   return (
//     <Navbar expand="lg">
//       <Navbar.Brand href="index">
//         <img src={logoImage} className="logo" alt="Logo" />
//       </Navbar.Brand>
//       <Navbar.Toggle aria-controls="navbar-menu" />
//       <Navbar.Collapse id="navbar-menu">
//         <Nav className="mr-auto">
//           <Nav.Link href="index">Home</Nav.Link>
//           <NavDropdown title="About" id="basic-nav-dropdown">
//             <NavDropdown.Item href="international">International</NavDropdown.Item>
//             <NavDropdown.Item href="mul">Multiple</NavDropdown.Item>
//           </NavDropdown>
//           <NavDropdown title="Districts" id="basic-nav-dropdown">
//             <NavDropdown.Item href="320a">320A</NavDropdown.Item>
//             <NavDropdown.Item href="320b">320B</NavDropdown.Item>
//             <NavDropdown.Item href="320c">320C</NavDropdown.Item>
//             <NavDropdown.Item href="320d">320D</NavDropdown.Item>
//             <NavDropdown.Item href="320e">320E</NavDropdown.Item>
//             <NavDropdown.Item href="320f">320F</NavDropdown.Item>
//             <NavDropdown.Item href="320g">320G</NavDropdown.Item>
//           </NavDropdown>
//           <Nav.Link href="contact">Contact</Nav.Link>
//         </Nav>
//       </Navbar.Collapse>
//       <div className="attr-nav">
      
//     <a href="upload"><button className="btn btn-primary">upload</button></a>
      
//     </div>
//     </Navbar>
    
//   );
// }

// export default TopNavbar;
