// import React, { useState, useEffect } from 'react';
// import Container from 'react-bootstrap/Container';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
// import Form from 'react-bootstrap/Form';
// import Offcanvas from 'react-bootstrap/Offcanvas';
// import { FaLocationDot } from "react-icons/fa6";
// import { FaPhoneAlt } from "react-icons/fa";
// import { MdOutlineMail } from "react-icons/md";
// import Stack from 'react-bootstrap/Stack';
// import logoImage from './img/logoImage.jpg';
// import { Button, OffcanvasBody } from 'react-bootstrap';


// function Header() {

    


//     return (
//         <div>

//             <Container gap={3} style={{ backgroundColor: 'lightgrey' }}>
//                 <Row>
//                     <Col >
//                         <img src={logoImage} alt="Description of the image" height='80%' width='100%' />
//                     </Col>
//                     <Col xs={6}>
//                         <div className="p-3 ms-auto"> <p> <FaLocationDot />Address</p>
//                             <p>Lions Bhavan, Lakpath buliding, Secunderabad</p>
//                         </div>
//                     </Col><div className="vr" />
//                     <Col >
//                         <div className="p-3"><p><MdOutlineMail />EMAIL</p>
//                             <p>info@lions320md.org</p>
//                         </div>
//                     </Col><div className="vr" />
//                     <Col>
//                         <div className="p-3"><p><FaPhoneAlt />PHONE </p>
//                             <p>+919849915567</p>
//                         </div>
//                     </Col>
//                 </Row>
//             </Container>
//             <br></br>
           
//         </div>
//     )
// }

// export default Header;



import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';


import { MdOutlineMail } from 'react-icons/md';

function Header() {
  return (
    <div>
      <Container fluid style={{ backgroundColor: 'lightgrey' }}>
        <Row className="align-items-center">
          <Col xs={2}>
            
          </Col>






          {/* <Col xs={12} md={4} className="py-3">
            <div className="d-flex flex-column">
              <p className="mb-0"><FaMapMarkerAlt /> Address</p>
              <p className="mb-0">Lions Bhavan, Lakpath buliding, Secunderabad</p>
            </div>
          </Col> */}
          {/* <Col xs={12} md={3} className="py-3">
            <div className="d-flex flex-column">
              <p className="mb-0"><MdOutlineMail /> EMAIL</p>
              <p className="mb-0">info@lions320md.org</p>
            </div>
          </Col>
          <Col xs={12} md={3} className="py-3">
            <div className="d-flex flex-column">
              <p className="mb-0"><FaPhoneAlt /> PHONE</p>
              <p className="mb-0">+919848074552</p>
            </div>
          </Col> */}
        </Row>
      </Container>
      <br />
    </div>
  );
}

export default Header;
