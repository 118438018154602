import React from 'react'
import { Carousel } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../../Header/Header';
import TopNavbar from '../TopNavbar'
import Footer from '../../Footer/Footer'
import banner10 from '../img/banner 10.png'
import a320 from '../img/a320.jpg'
import A1 from '../img/A1.jpg'
import a2 from '../img/a2.jpg'
import a3 from '../img/a3.jpg'

function A320() {
  return (
    <div>
      <Header />
      <TopNavbar />

      <div className="banner-area">
        <Carousel id="bootcarousel" className="text-light heading-uppercase animate_text" controls indicators={false}>
          {/* <Carousel.Item className="bg-cover img-fluid active">
            <img src={banner10} className="d-block w-100" alt="First slide" />
          </Carousel.Item> */}
          <Carousel.Item className="bg-cover img-fluid"  style={{padding:"30px"}}>
            <img src={a320} className="d-block w-100" alt="Second slide" />
          </Carousel.Item>
        </Carousel>
      </div>
      <br></br>
      <section id="about-us" className="about-us">
        <Container data-aos="fade-up">
          <div className="section-title">
            <h2>DG TEAM - DISTRICT 320A</h2>
          </div>
          <Row style={{padding:"100px"}}>
            <Col lg={4} md={6} className="d-flex align-items-stretch">
              <div className="icon-box iconbox-blue">
                <div className="icon">
                  <img src={a2} style={{ width: '200px', height:'200px' }} alt="J.Raghu Babu" />
                </div>
                <h4>Kotewsara Rao</h4>
                <p style={{color:"black"}}>District Governor<br /><br/>Mobile: 9848219972</p>
              </div>
            </Col>
            <Col lg={4} md={6} className="d-flex align-items-stretch">
              <div className="icon-box iconbox-blue">
                <div className="icon">
                  <img src={a3}  alt="Harinarayana" style={{ width: '200px', height: '200px'}} />
                </div>
                <h4>Dr.G Mahendera Kumar Reddy FDI</h4>
                <p style={{color:"black"}}>First Vice District Governor<br /><br />Mobile: 9848219972</p>
              </div>
            </Col>
            <Col lg={4} md={6} className="d-flex align-items-stretch">
              <div className="icon-box iconbox-blue ">
                <div className="icon">
                  <img src={A1} style={{ width: '200px',height:'200px' }} alt="Lion Suresh Jagnani" />
                </div>
                <h4>Lion Suresh Jagnani</h4>
                <p style={{color:"black"}}>Second Vice District Governor<br/><br/>Mobile: 9885189821</p>
              </div>
            </Col>
          </Row>

          {/* <div className="section-title">
            <h2>GALLERY - DISTRICT 320A</h2>
          </div> */}
          {/* <Row>
            <Col lg={4} md={6} className="d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
              <div className="icon-box iconbox-blue">
                <div className="icon">
                  <img src="https://lionsmd320.org/uploads/210730080723.jpg" style={{ width: '50%', cursor: 'zoom-in' }} alt="sxc" title="sxc" />
                </div>
                <span>sxc</span>
              </div>
            </Col>

          </Row> */}
        </Container>
      </section><br></br>

      <Footer />
    </div>
  )
}

export default A320
