// import React from 'react'
// import logoImage from './img/logoImage.jpg'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faFacebookF, faTwitter, faPinterest, faYoutube } from '@fortawesome/free-brands-svg-icons';
// import { Row, Col } from 'react-bootstrap';


// function Footer() {
//     return (
//         <div className='container ' style={{ display: 'flex', backgroundColor: 'black', color: 'white' }}>
//             <div className="f-items default-padding ">
//                 <Row>
//                     <Col>
//                         <div className="col-md-12 equal-height item" style={{ height: "192px" }}>
//                             <div className="f-item">
//                                 <img src={logoImage} className="logo" alt="Logo" height="80px" />
//                                 <p>
//                                     Lions Bhavan
//                                     Lakpath building
//                                     secunderabad
//                                 </p><br></br>
//                                 <Row className="social" style={{ display: 'flex', justifyContent: 'center' }}>
//                                     <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
//                                         <Col>
//                                             <li style={{ display: 'inline-block', margin: '0 10px' }}>
//                                                 <a href="#">
//                                                     <FontAwesomeIcon icon={faFacebookF} />
//                                                 </a>
//                                             </li>
//                                             <li style={{ display: 'inline-block', margin: '0 10px' }}>
//                                                 <a href="#">
//                                                     <FontAwesomeIcon icon={faTwitter} />
//                                                 </a>
//                                             </li>
//                                             <li style={{ display: 'inline-block', margin: '0 10px' }}>
//                                                 <a href="#">
//                                                     <FontAwesomeIcon icon={faPinterest} />
//                                                 </a>
//                                             </li>
//                                             <li style={{ display: 'inline-block', margin: '0 10px' }}>
//                                                 <a href="#">
//                                                     <FontAwesomeIcon icon={faYoutube} />
//                                                 </a>
//                                             </li>
//                                         </Col>
//                                     </ul>
//                                 </Row>
//                             </div>
//                         </div>
//                     </Col>

//                     <Col className="equal-height item">
//                         <div className="col-md-12 f-item recent-post">
//                             <h4>Important Links</h4>
//                             <ul className="important-links-list">
//                                 <li>
//                                     <a href="#" target="_blank">President Theme</a>
//                                 </li>
//                                 <li>
//                                     <a href="#" target="_blank">Leadership Development Resource Center</a>
//                                 </li>
//                                 <li>
//                                     <a href="#" target="_blank">Club Resource Center</a>
//                                 </li>
//                                 <li>
//                                     <a href="#" target="_blank">District Resource Center</a>
//                                 </li>
//                                 <li>
//                                     <a href="#" target="_blank">Invite Members</a>
//                                 </li>
//                             </ul>
//                         </div>
//                     </Col>

//                     <Col>
//                         <div className="col-md-12 equal-height item" style={{ height: "326px" }}>
//                             <div className="f-item recent-post">
//                                 <h4>Important Links</h4>
//                                 <ul>
//                                     <li><a href="https://www.lionsclubs.org/en/search-results?keys=lions+internatonal+melvin+jones+memorial" target="_blank">Lions International Melvin Jones Memorial</a></li>
//                                     <li><a href="https://www.lionsclubs.org/en/resources-for-members/resource-center" target="_blank">Lions Club International Work</a></li>
//                                     <li><a href="https://www.lionsclubs.org/en/search-results?keys=lions+international+impact" target="_blank">Lions Club International Impact</a></li>
//                                     <li><a href="https://www.lionsclubs.org/en/search-results?keys=group+volunter+opportunities" target="_blank">Group Volunteer Oppurtunities</a></li>
//                                     <li><a href="https://www.lionsclubs.org/en/explore-our-clubs/our-global-causes" target="_blank">Global Health Programs</a></li>
//                                 </ul>
//                             </div>
//                         </div>
//                     </Col>

//                     <Col>
//                         <div className="col-md-12 equal-height item" style={{ height: "326px" }}>
//                             <div className="f-item recent-post">
//                                 <h4>Quick Links</h4>
//                                 <ul>
//                                     <li><a href="https://www.lionsclubs.org/en/discover-our-foundation/mission" target="_blank">LCIF</a></li>
//                                     <li><a href="https://www.lions-quest.org/" target="_blank">Lions Quest</a></li>
//                                     <li><a href="https://www.lionsclubs.org/en/resources-for-members/resource-center/create-e-district-house" target="_blank">E-club House In India</a></li>
//                                     <li><a href="https://www.lionsclubs.org/en/resources-for-members/top-25-searches" target="_blank">Latest Statistics</a></li>
//                                     <li><a href="https://www.lionsclubs.org/en/blog" target="_blank">Blogs to Governor</a></li>
//                                 </ul>
//                             </div>
//                         </div>
//                     </Col>
//                 </Row>
//             </div>
//         </div>
//     )
// }

// export default Footer;

import React from 'react';
import logoImage from './img/logoImage.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faPinterest, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { Row, Col, Container } from 'react-bootstrap';
import './Footer.css';

function Footer() {
    return (
        <div className='container-fluid footer-container'>
            <div className='responsive-contain m-auto'>
            <div className="f-items default-padding ">
                <Row>
                    <Col>
                        <div className="col-md-12 equal-height item">
                            <div className="f-item">
                                <img src={logoImage} className="logo" alt="Logo" />
                                <p style={{color:'white'}}>
                                    Lions Bhavan
                                    Lakpath building
                                    secunderabad
                                </p>
                                <div className="social">
                                    <ul>
                                        <li>
                                            <a href="#">
                                                <FontAwesomeIcon icon={faFacebookF} />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <FontAwesomeIcon icon={faTwitter} />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <FontAwesomeIcon icon={faPinterest} />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                                <FontAwesomeIcon icon={faYoutube} />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </Col>

                    <Col>
                        <div className="col-md-12 equal-height item ">
                            <div className="f-item recent-post">
                                <h4>Important Links</h4>
                                <ul className="important-links-list">
                                    <li>
                                        <a href="#" target="_blank">President Theme</a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">Leadership Development Resource Center</a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">Club Resource Center</a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">District Resource Center</a>
                                    </li>
                                    <li>
                                        <a href="#" target="_blank">Invite Members</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </Col>

                    <Col>
                        <div className="col-md-12 equal-height item">
                            <div className="f-item recent-post">
                                <h4>Important Links</h4>
                                <ul>
                                    <li><a href="https://www.lionsclubs.org/en/search-results?keys=lions+internatonal+melvin+jones+memorial" target="_blank">Lions International Melvin Jones Memorial</a></li>
                                    <li><a href="https://www.lionsclubs.org/en/resources-for-members/resource-center" target="_blank">Lions Club International Work</a></li>
                                    <li><a href="https://www.lionsclubs.org/en/search-results?keys=lions+international+impact" target="_blank">Lions Club International Impact</a></li>
                                    <li><a href="https://www.lionsclubs.org/en/search-results?keys=group+volunter+opportunities" target="_blank">Group Volunteer Oppurtunities</a></li>
                                    <li><a href="https://www.lionsclubs.org/en/explore-our-clubs/our-global-causes" target="_blank">Global Health Programs</a></li>
                                </ul>
                            </div>
                        </div>
                    </Col>

                    <Col>
                        <div className="col-md-12 equal-height item">
                            <div className="f-item recent-post">
                                <h4>Quick Links</h4>
                                <ul>
                                    <li><a href="https://www.lionsclubs.org/en/discover-our-foundation/mission" target="_blank">LCIF</a></li>
                                    <li><a href="https://www.lions-quest.org/" target="_blank">Lions Quest</a></li>
                                    <li><a href="https://www.lionsclubs.org/en/resources-for-members/resource-center/create-e-district-house" target="_blank">E-club House In India</a></li>
                                    <li><a href="https://www.lionsclubs.org/en/resources-for-members/top-25-searches" target="_blank">Latest Statistics</a></li>
                                    <li><a href="https://www.lionsclubs.org/en/blog" target="_blank">Blogs to Governor</a></li>
                                </ul>
                            </div>
                        </div>
                    </Col>
                </Row>

            </div>
            <div className="footer-bottom  text-light">
                <Container>
                    <hr className="my-4" />
                    <Row className="align-items-center">
                        <Col md={6} className='copy'>
                            <p>© Copyright 2021. All Rights Reserved by <a href="#">emedha technologies</a></p>
                        </Col>
                        <Col md={6} className="text-right">
                            <ul className="list-inline mb-0">
                                <li className="list-inline-item">
                                    <a href="#">Terms of Use</a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="#">License</a>
                                </li>
                                <li className="list-inline-item">
                                    <a href="#">Support</a>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
            </div>
        </div>
    );
}

export default Footer;
