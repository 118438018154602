// import React from 'react'
// import { Carousel } from 'react-bootstrap';
// import { Row, Col, Card } from 'react-bootstrap';
// import Header from '../../Header/Header';
// import TopNavbar from '../TopNavbar'
// import Footer from '../../Footer/Footer'
// import banner10 from '../img/banner10.png'
// import banner11 from '../img/banner11.png'
// import multidstrct from '../img/multidstrct.png'
// import sunilkumar from '../img/sunilkumar.jpg'
// import baburao from '../img/baburao.png'
// import vidyasagar from '../img/vidyasagar.png'
// import jagani1 from '../img/jagani1.jpg'
// import radhakrishna from '../img/radhakrishna.png'
// import santhosh from '../img/santhosh.png'
// import kotesh from '../img/kotesh.png'


// function Multiple() {
//     return (
//         <div>
//             <Header />
//             <TopNavbar />

//             <div className="banner-area">
//                 <Carousel id="bootcarousel" className="text-light heading-uppercase animate_text" controls indicators={false}>
//                     <Carousel.Item className="bg-cover img-fluid active">
//                         <img src={banner10} className="d-block w-100" alt="First slide" />
//                     </Carousel.Item>
//                     <Carousel.Item className="bg-cover img-fluid">
//                         <img src={banner11} className="d-block w-100" alt="Second slide" />
//                     </Carousel.Item>
//                 </Carousel>
//             </div>
//             <div className='lg={12} md={12}'>
//                 <h3 style={{ marginRight: '60%' }}>Lions <strong> Multiple District</strong></h3>
//                 <div>
//                     <img src={multidstrct} alt='multi' height='70%' width='60%' />
//                 </div>
//                 <div class="col-lg-12 col-md-12">
//                     <p style={{ color: '#666666', lineHeight: '26px', margin: '0 0 15px', textTransform: 'none', fontWeight: '400', marginLeft: '10%', marginRight: '5%' }}>Multiple District 320 was formed in the year 2016-17 and was Inaugrated
//                         by our international president Naresh ji Agarwal on 7th July 2016 at Marriot Hotel
//                         Hyderabad
//                         .</p>
//                 </div>
//                 <div class="col-lg-12 col-md-12">

//                     <p style={{ color: '#666666', lineHeight: '26px', margin: '0 0 15px', textTransform: 'none', fontWeight: '400', marginLeft: '10%', marginRight: '5%' }}>The Multiple District is the seventh Multiple District in india consisting of Districts
//                         of Telangana State,part of Maharastra and part of Karnataka .There are nearly 18413 Members
//                         with 506 Clubs.
//                     </p>
//                 </div>
//                 <>
//       <div>
//         <h2 style={{ marginLeft: '10%' }}><strong>Our Leaders</strong></h2>
//         <Row style={{ padding: '1%' }}>
//           <Col xs={12} sm={6} md={6} lg={3} style={{ padding: '1%' }}>
//             <Card>
//               <Card.Img src={sunilkumar} style={{ padding: '1% 0% 1% 2%', minHeight: '70%' }} />
//               <Card.Body>
//                 <b style={{ textAlign: 'center' }}><a href="#" style={{ color: '#000000' }}>R Sunil Kumar</a></b>
//                 <p>PAST INTERNATIONAL DIRECTOR</p>
//               </Card.Body>
//             </Card>
//           </Col>
//           <Col xs={12} sm={6} md={6} lg={3} style={{ padding: '1%' }}>
//             <Card>
//               <Card.Img src={jagani1} height="280px" />
//               <Card.Body>
//                 <b style={{ textAlign: 'center' }}><a href="#" style={{ color: '#000000' }}>Beemaiah Jagani</a></b>
//                 <p>GAT AREA LEADER</p>
//               </Card.Body>
//             </Card>
//           </Col>
//           <Col xs={12} sm={6} md={6} lg={3} style={{ padding: '1%' }}>
//             <Card>
//               <Card.Img src={baburao} height="280px" />
//               <Card.Body>
//                 <b style={{ textAlign: 'center' }}><a href="#" style={{ color: '#000000' }}>G Babu Rao</a></b>
//                 <p>LCIF AREA LEADER</p>
//               </Card.Body>
//             </Card>
//           </Col>
//           <Col xs={12} sm={6} md={6} lg={3} style={{ padding: '1%' }}>
//             <Card>
//               <Card.Img src={vidyasagar} style={{ padding: '1% 0% 1% 2%', minHeight: '70%' }} />
//               <Card.Body>
//                 <b style={{ textAlign: 'center' }}><a href="#" style={{ color: '#000000' }}>M. Vidyasagar Reddy</a></b>
//                 <p>CHAIRMAN</p>
//               </Card.Body>
//             </Card>
//           </Col>
//         </Row>
//       </div>
//       <div>
//         <Row>
//           <Col xs={12} sm={6} md={6} lg={4} style={{ padding: '1%' }}>
//             <Card>
//               <Card.Img src={kotesh} style={{ padding: '1% 0% 1% 2%', minHeight: '70%' }} />
//               <Card.Body>
//                 <b style={{ textAlign: 'center' }}><a href="#" style={{ color: '#000000' }}>A Koteswara Rao</a></b>
//                 <p>Vice Chairman</p>
//               </Card.Body>
//             </Card>
//           </Col>
//           <Col xs={12} sm={6} md={6} lg={4} style={{ padding: '1%' }}>
//             <Card>
//               <Card.Img src={radhakrishna} style={{ width: '100%', height: '415px' }} />
//               <Card.Body>
//                 <b style={{ textAlign: 'center' }}><a href="#" style={{ color: '#000000' }}>S RadhaKrishna</a></b>
//                 <p>Secretary</p>
//               </Card.Body>
//             </Card>
//           </Col>
//           <Col xs={12} sm={6} md={6} lg={4} style={{ padding: '1%' }}>
//             <Card>
//               <Card.Img src={santhosh} style={{ padding: '1% 0% 1% 2%', minHeight: '70%' }} />
//               <Card.Body>
//                 <b style={{ textAlign: 'center' }}><a href="#" style={{ color: '#000000' }}>Nagula Santosh</a></b>
//                 <p>Treasurer</p>
//               </Card.Body>
//             </Card>
//           </Col>
//         </Row>
//       </div>
//     </>
//             </div>

//             <Footer />
//         </div >
//     )
// }

// export default Multiple


import React from 'react';
import { Carousel, Row, Col, Card } from 'react-bootstrap';
import Header from '../../Header/Header';
import TopNavbar from '../TopNavbar';
import Footer from '../../Footer/Footer';
import banner10 from '../img/banner 10.png';
import banner11 from '../img/banner11.png';
import multidstrct from '../img/multidstrct.png';
import sunilkumar from '../img/sunilkumar.jpg';
import baburao from '../img/BabuRaoPmjf.jpg';
import Deepak from '../img/Deepak.png';
import Narender from '../img/Narender.png';
import mult from '../About/mult.css';
import g1 from '../img/g1.png'
import c1 from '../img/c1.png'
import e1 from '../img/e1.png'
import f1 from '../img/f1.png'
import ResumallaReddy from '../img/ResumallaReddy.png'
function Multiple() {
  return (
    <div>
      <Header />
      <TopNavbar />

      <div className="banner-area">
        <Carousel id="bootcarousel" className="text-light heading-uppercase animate_text" controls indicators={false}>
          <Carousel.Item className="bg-cover img-fluid active">
            {/* <img src={banner10} className="d-block w-100" alt="First slide" /> */}
          </Carousel.Item>
          <Carousel.Item className="bg-cover img-fluid" style={{padding:"30px"}}>
            <img src={banner11} className="d-block w-100" alt="Second slide" />
          </Carousel.Item>
        </Carousel>
      </div>

      <div className="container">
        <h3 className="mt-4">Lions <strong>Multiple District</strong></h3>
        <div className="text-center">
          <img src={multidstrct} alt="multi" className="img-fluid mt-3 mb-3" style={{ maxWidth: '60%' }} />
        </div>
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <p style={{ color: '#666666', lineHeight: '26px', marginBottom: '15px'}}>
              Multiple District 320 was formed in the year 2016-17 and was inaugurated by our International President Naresh Ji Agarwal on 7th July 2016 at Marriott Hotel Hyderabad.
            </p>
            <p style={{ color: '#666666', lineHeight: '26px', marginBottom: '15px' }}>
              The Multiple District is the seventh Multiple District in India consisting of districts of Telangana State, part of Maharashtra, and part of Karnataka. There are nearly 18,413 members with 506 clubs.
            </p>
          </div>
        </div>

        <div className="mt-4" >
          <h2 className="mb-4">Our Leaders</h2>
          <Row xs={1} md={1} lg={4}  className='leaders'>        
            {[            
              { img: baburao, name: 'G Babu Rao', position:'INTERNATIONAL DIRECTOR 2023-2025'  },
              { img: sunilkumar, name: 'R Sunil Kumar', position: 'PAST INTERNATIONAL DIRECTOR' },
              { img: Deepak, name: 'Deepak Bhatacharjee', position: 'GAT AREA LEADER' },
              { img: Narender, name: 'S. Narender Reddy', position: 'LCIF AREA LEADER' },
              { img: g1, name: 'Raja Reddy', position: 'CHAIRMAN' },
              { img: c1, name: 'K.Harish Reddy', position: 'Vice Chairman' },
              { img: f1, name: 'N.Venkateshvara Rao', position: 'Secretary' },
              { img: e1, name: 'CH.Siva Prasad', position: 'Joint Secretary' },
              { img: ResumallaReddy, name: 'Resu Malla Reddy', position: 'Treasurer' },              
            ].map((leader, index) => (
              <Col key={index}>
                <Card className="mb-8 d-flex flex-column align-items-center justify-content-center" >
                  <Card.Img variant="top" src={leader.img} className="p-2" style={{width:"180px",height:"180px"}}/>
                  <Card.Body>
                    <Card.Title className="mb-0">{leader.name}</Card.Title>
                    <Card.Text className='seven'>{leader.position}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Multiple;
