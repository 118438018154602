import React, { useState } from 'react';
import { Carousel } from 'react-bootstrap';
import { Container, Row, Col, Form, Button, Modal } from 'react-bootstrap';
import Header from '../../Header/Header';
import TopNavbar from '../TopNavbar'
import Footer from '../../Footer/Footer'
// import banner10 from '../img/banner 10.png'
import banner11 from '../img/banner11.png'

function Upload() {

    const [showModal, setShowModal] = useState(false);

    const handleSubmit = (event) => {
        event.preventDefault();
        // Add your form submission logic here
        // Example: send form data to the server
        // Show modal after successful upload
        setShowModal(true);
    };


    return (
        <div>
            <Header />
            <TopNavbar />

            <div className="banner-area">
                <Carousel id="bootcarousel" className="text-light heading-uppercase animate_text" controls indicators={false}>
                    {/* <Carousel.Item className="bg-cover img-fluid active">
                        <img src={banner10} className="d-block w-100" alt="First slide" />
                    </Carousel.Item> */}
                    <Carousel.Item className="bg-cover img-fluid">
                        <img src={banner11} className="d-block w-100" alt="Second slide" />
                    </Carousel.Item>
                </Carousel>
            </div>
            <br></br>
            <Container>
            <Row className="justify-content-md-center">
                <Col xs={12} md={8}>
                    <h4 style={{ textAlign: 'center',marginLeft:'20px' }}>UPLOADS HERE</h4>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="image1" data-aos="fade-up">
                            <Form.Label><b>Upload Photo (Please upload only PNG, JPG, GIF AND JPEG Files and size must be 2 MB)<sup>*</sup></b></Form.Label>
                            <Form.Control type="file" accept="image/*" required />
                        </Form.Group><br></br>
                       
                        <Form.Group controlId="text" data-aos="fade-up">
                            <Form.Label><b>Add your Caption Here<sup>*</sup></b></Form.Label>
                            <Form.Control as="textarea" rows={1} required />
                        </Form.Group><br></br>
                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="district" data-aos="fade-up">
                                    <Form.Label ><b>Districts<sup>*</sup></b></Form.Label>
                                    <Form.Control as="select" required>
                                        <option value="">---SELECT DISTRICT---</option>
                                        <option value="320A">320A</option>
                                        <option value="320B">320B</option>
                                        <option value="320C">320C</option>
                                        <option value="320D">320D</option>
                                        <option value="320E">320E</option>
                                        <option value="320F">320F</option>
                                        <option value="320G">320G</option>
                                        <option value="320H">320H</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                            <Form.Group controlId="activity" data-aos="fade-up">
                                <Form.Label><b>Service Activity<sup>*</sup></b></Form.Label>
                                <Form.Control as="select" required>
                                    <option value="">---Select Activity---</option>
                                    <option value="Hunger-Relief">Hunger Relief</option>
                                    <option value="Environment">Environment</option>
                                    <option value="Childhood-Cancer">Childhood Cancer</option>
                                    <option value="Medical-Camps">Medical Camps</option>
                                    <option value="Seminars">Seminars</option>
                                    <option value="Rallies-Awareness">Rellies & Awareness</option>
                                    <option value="Youth-Empowerment">Youth Empowerment</option>
                                    <option value="Diabetes">Diabets</option>
                                    <option value="Leo">Leo</option>
                                    <option value="Quest">Quest</option>
                                    <option value="Leadership Training">Leadership Training</option>
                                    <option value="Permanent Projects">Permanent Projects</option>
                                    <option value="Vision">Vision</option>
                                    <option value="Other">Other</option>
                                </Form.Control>
                            </Form.Group><br></br>
                            </Col>
                        </Row><br></br>
                       
                        <Button data-aos="fade-up" variant="primary" type="submit">UPLOAD</Button>
                    </Form><br></br>
                      
                </Col>
            </Row>
            <Modal show={showModal} onHide={() => setShowModal(false)} id="success">
                <Modal.Header closeButton>
                    <Modal.Title>Modal title</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <b>Image Uploaded successfully</b>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Close</Button>
                </Modal.Footer>
            </Modal>
        </Container>


            <Footer />
        </div>
    )
}

export default Upload
