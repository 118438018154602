import React from 'react'
import { Carousel } from 'react-bootstrap';
import Header from '../../Header/Header';
import TopNavbar from '../TopNavbar'
import Footer from '../../Footer/Footer'
// import banner10 from '../img/banner 10.png'
import banner11 from '../img/banner11.png'
import loc from '../img/loc.png'

function Contact() {
  return (
    <div>
    <Header />
    <TopNavbar/>
<br></br>
    <div className="banner-area">
    <Carousel id="bootcarousel" className="text-light heading-uppercase animate_text" controls indicators={false}>
      {/* <Carousel.Item className="bg-cover img-fluid active">
        <img src={banner10} className="d-block w-100" alt="First slide" />
      </Carousel.Item> */}
      <Carousel.Item style={{padding:"30px"}}>
        <img src={banner11} className="d-block w-100" alt="Second slide" />
      </Carousel.Item>
    </Carousel>
  </div>
  <br></br>
<div>
    {/* <img src={loc} alt='location' height='100%' width='85%'/> */}
    <div style={{maxWidth:'500px',color:'red',width:'500px',height:"500px"}}><div id="embed-ded-map-canvas" style={{height:'500px', width:'600px',maxWidth:"100%"}}><iframe style={{height:'550px',width:'1305px',padding:"35px"}} frameborder="0" src="https://www.google.com/maps/embed/v1/place?q=https://www.google.co.in/maps/place/Lions+Bhavan+,+Paradise/&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"></iframe></div><a class="google-map-code-enabler" href="https://www.bootstrapskins.com/themes" id="get-data-for-embed-map">premium bootstrap themes</a><style>#embed-ded-map-canvas </style></div>
</div>
<br></br>
    <Footer/> 
  </div>
  )
}

export default Contact
