import React from 'react'
import { Carousel } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../../Header/Header';
import TopNavbar from '../TopNavbar'
import Footer from '../../Footer/Footer'
import banner10 from '../img/banner 10.png'
import d320 from '../img/d320.jpg'
import D1 from '../img/D1.jpg'
import d2 from '../img/d2.jpg'
import d3 from '../img/d3.jpg'


function D320() {
  return (
    <div>
    <Header />
    <TopNavbar/>

    <div className="banner-area">
    <Carousel id="bootcarousel" className="text-light heading-uppercase animate_text" controls indicators={false}>
      {/* <Carousel.Item className="bg-cover img-fluid active">
        <img src={banner10} className="d-block w-100" alt="First slide" />
      </Carousel.Item> */}
      <Carousel.Item className="bg-cover img-fluid"  style={{padding:"30px"}}>
        <img src={d320} className="d-block w-100" alt="Second slide" />
      </Carousel.Item>
    </Carousel>
  </div>
<br></br>
<section id="about-us" className="about-us">
      <Container data-aos="fade-up">
        <div className="section-title">
          <h2>DG TEAM - DISTRICT 320D</h2>
        </div><br></br>
        <Row style={{padding:"100px"}}>
          <Col lg={4} md={6} className="d-flex align-items-stretch">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <img src={d2} style={{ width:'200px',height:'200px' }} alt="Nagesh Pampati" />
              </div>
              <h4>Nagesh Pampati</h4>
              <p style={{color:"black"}}>District Governor<br/><br />Mobile: 9866108933</p>
            </div>
          </Col>
          <Col lg={4} md={6} className="d-flex align-items-stretch">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <img src={d3} style={{ width:'200x',height:'200px' }} alt="P.Lakshmi" />
              </div>
              <h4>A. Amarnath Rao</h4>
              <p style={{color:"black"}}>First Vice District Governor<br/><br />Mobile: 9866014575</p>
            </div>
          </Col>
          <Col lg={4} md={6} className="d-flex align-items-stretch">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <img src={D1} style={{ width:'200px',height:'200px' }} alt="Nagesh Pampati" />
              </div>
              <h4>Dr.M.VijayaLakshmi</h4>
              <p style={{color:"black"}}>Second Vice District Governor<br/><br/>Mobile: 9666654647</p>
            </div>
          </Col>
        </Row>

        {/* <div className="section-title">
          <h2>GALLERY - DISTRICT 320D</h2>
        </div> */}
        {/* <Row>
          <Col lg={4} md={6} className="d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <img src="https://lionsmd320.org/uploads/210929110940.jpg" style={{ width: '50%', cursor: 'zoom-in' }} alt="Health check" title="Health check" />
              </div>
              <span>Health check</span>
            </div>
          </Col>

          
        </Row> */}
      </Container>
    </section><br></br>
    <Footer/> 
  </div>
  )
}

export default D320
