import React from 'react'
import { Carousel } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../../Header/Header';
import TopNavbar from '../TopNavbar'
import Footer from '../../Footer/Footer'
// import banner10 from '../img/banner 10.png'
import g320 from '../img/g320.jpg'
import G1 from '../img/G1.jpg'
import g2 from '../img/g2.jpg'
import g3 from '../img/g3.png'

function G320() {
  return (
    <div>
    <Header />
    <TopNavbar/>

    <div className="banner-area">
    <Carousel id="bootcarousel" className="text-light heading-uppercase animate_text" controls indicators={false}>
      {/* <Carousel.Item className="bg-cover img-fluid active">
        <img src={banner10} className="d-block w-100" alt="First slide" />
      </Carousel.Item> */}
      <Carousel.Item className="bg-cover img-fluid" style={{padding:"30px"}}>
        <img src={g320} className="d-block w-100" alt="Second slide" />
      </Carousel.Item>
    </Carousel>
  </div><br/>
  <section id="about-us" className="about-us">
      <Container data-aos="fade-up">
        <div className="section-title">
          <h2>DG TEAM - DISTRICT 320G</h2>
        </div>
        <Row style={{padding:"100px"}}>
          <Col lg={4} md={6} className="d-flex align-items-stretch">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <img src={g2} style={{ width:'200px',height:'200px' }} alt="N Venkateswara Rao" />
              </div>
              <h4>N Venkateswara Rao</h4>
              <p style={{color:"black"}}>District Governor<br/><br />Mobile: 9440620211</p>
            </div>
          </Col>
          <Col lg={4} md={6} className="d-flex align-items-stretch">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <img src={g3} style={{ width:'200px',height:'200px' }} alt="Kodhandaram Simahraju" />
              </div>
              <h4>Kodhandaram Simahraju</h4>
              <p style={{color:"black"}}>First Vice District Governor<br/><br />Mobile: 9885722026</p>
            </div>
          </Col>
          <Col lg={4} md={6} className="d-flex align-items-stretch">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <img src={G1} style={{ width:'200px',height:'200px' }} alt="Mora Bhadresham" />
              </div>
              <h4>Mora Bhadresham</h4>
              <p style={{color:"black"}}>Second Vice District Governor<br/><br />Mobile: 9440304303</p>
            </div>
          </Col>
        </Row>

        {/* <div className="section-title">
          <h2>GALLERY - DISTRICT 320G</h2>
        </div> */}
        {/* <Row>
          <Col lg={4} md={6} className="d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <img src="https://lionsmd320.org/uploads/210925060904.jpg" style={{ width: '50%', cursor: 'zoom-in' }} alt="Distribution of Groceries" title="Distribution of Groceries" />
              </div>
              <span>Distribution of Groceries</span>
            </div>
          </Col>

      
        </Row> */}
      </Container>
    </section><br/>

    <Footer/> 
  </div>
  )
}

export default G320
