import React from 'react'
import { Carousel } from 'react-bootstrap';
import Header from '../../Header/Header';
import TopNavbar from '../TopNavbar'
import Footer from '../../Footer/Footer'
import Oliveira from '../img/Oliveira.png'
import banner11 from '../img/banner11.png'
import { Container, Row, Col, Card } from 'react-bootstrap';
import main from '../img/main.jpg'
import melvinjones from '../img/melvinjones.jpg'
import symbol from '../img/symbol.png'
import Brain from '../img/Brain.jpg'
import inv from '../img/inv.png'
import ManojShah from '../img/ManojShah.jpeg'
import singhap from '../img/singhap.jpg'
import mark from '../img/lyon-mark.png'

function International () {
  return (
    <div>
    <Header />
    <TopNavbar/>

    <div className="banner-area">
    <Carousel id="bootcarousel" className="text-light heading-uppercase animate_text" controls indicators={false}>
      <Carousel.Item className="bg-cover img-fluid active">
        {/* <img src={banner10} className="d-block w-100" alt="First slide" /> */}
      </Carousel.Item>
      <Carousel.Item className="bg-cover img-fluid" style={{padding:"30px"}}>
        <img src={banner11} className="d-block w-100" alt="Second slide" />
      </Carousel.Item>
    </Carousel>
  </div><br></br>

  <section id="about-us" className="about-us">
      <Container data-aos="fade-up">
        <div className="section-title">
          <h2>STORY BEGINS</h2>
        </div><br></br>
        <Row className="content">
          <Col lg={6} data-aos="fade-right">
            <img src={main} alt="Main" height='100%' width='80%' />
          </Col>
          <Col lg={6} md={6} className="pt-4 pt-lg-0" data-aos="fade-left">
            <p style={{color:"black",marginBottom:"250px",textAlign:"start"}}>
              In 1917, Melvin Jones, a 38-year-old Chicago business leader, told members of his local business club they should reach beyond business issues and address the betterment of their communities and the world. Jones' group, the Business Circle of Chicago, agreed.
              After contacting similar groups around the United States, an organizational meeting was held on June 7, 1917, in Chicago, Illinois, USA. The new group took the name of one of the invited groups, the "Association of Lions Clubs," and a national convention was held in Dallas, Texas, USA in October of that year. A constitution, by-laws, objects and a code of ethics were approved.
              Within three years, Lions became an international organization. Since then, we've earned high marks for both integrity and transparency. We're a well-run organization with a steady vision, a clear mission, and a long – and proud – history.
            </p>
          </Col>
        </Row>
      </Container>
    </section><br></br>
    <Container data-aos="fade-up">
      <div className="section-title">
        <h2>OUR MOTOS</h2>
      </div><br></br>
      <Row>
        <Col lg={4} md={6} className="d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
          <div className="icon-box iconbox-blue">
            <div className="icon">
              <img src={melvinjones} style={{ width: '100%' }} alt="Melvin Jones" />
            </div>
            <h4> Our Founder</h4>
            <p style={{color:"black"}}><b>In 1917, Melvin Jones, a 38-year-old Chicago business leader, told members of his local</b></p>
          </div>
        </Col>

        <Col lg={4} md={6} className="d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay="200">
          <div className="icon-box iconbox-orange">
            <div className="icon">
              <img src={symbol} style={{ width: '100%' }} alt="Vision" />
            </div>
            <h4>Our Vision</h4>
            <p style={{color:"black"}}><b>To be the global leader in community and humanitarian </b></p>
          </div>
        </Col>

        <Col lg={4} md={6} className="d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay="300">
          <div className="icon-box iconbox-pink">
            <div className="icon">
              <img src={symbol} style={{ width: '100%' }} alt="Mission" />
            </div>
            <h4>Our MISSION</h4>
            <p style={{color:"black"}}><b>To empower volunteers to serve their communities meet humanitarian needs, encourage peace and promote international understanding through Lions clubs</b></p>
          </div>
        </Col>

        <Col lg={4} md={6} className="d-flex align-items-stretch mt-4-lg-0" data-aos="zoom-in" data-aos-delay="200">
          <div className="icon-box iconbox-red">
            <div className="icon">
              <a href="assets/img/presidentialtheme.pdf"><img src={Oliveira} style={{ width: '100%' }} alt="A Heart for Service" /></a>
            </div>
            <h4>A HEART FOR SERVICE</h4>
            <p style={{color:"black"}}><b>Moving membership forward increasing leadership development, Promoting the fellowship of Lions</b></p>
          </div>
        </Col>
      </Row>
    </Container>
    <br></br>
    <section data-aos="fade-up">
      <Container>
        <div className="section-title">
          <h2 >OUR TEAM</h2>
        </div>
        <Row className="no-gutters clients-wrap clearfix">
          <Col lg={3} md={6} style={{ padding: '2%', }}>
            <Card>
              <Card.Img src={inv} height="270px" style={{ padding: '3% 1% 1% 2%' }} />
              <Card.Body>
                <Card.Title style={{ textAlign: 'center' }}>
                  <a href="assets/img/bio-townsend.pdf" style={{ color: '#000000' }}>Fabricio Oliveira</a>
                </Card.Title>
                <Card.Text style={{color:"black"}}>President</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} md={6} style={{ padding: '2%' }}>
            <Card>
              <Card.Img src={singhap} height="270px" style={{ padding: '3% 1% 1% 2%'}} />
              <Card.Body>
                <Card.Title style={{ textAlign: 'center' }}>
                  <a href="assets/img/bio-sheehan.pdf" style={{ color: '#000000' }}>A.P. singh</a>
                </Card.Title>
                <Card.Text style={{color:"black"}}>First Vice President</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} md={6} style={{ padding: '2%' }}>
            <Card>
              <Card.Img src={mark} height="270px" style={{ padding: '3% 1% 0% 2%'}} />
              <Card.Body>
                <Card.Title style={{ textAlign: 'center' }}>
                  <a href="assets/img/singh.jpg" style={{ color: '#000000'}}>Mark S. Layon</a>
                </Card.Title>
                <Card.Text style={{color:"black"}}>Second Vice President</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={3} md={6} sm={1} style={{ padding: '2%' }}>
            <Card>
              <Card.Img src={ManojShah} height="270px" style={{ padding: '3% 1% 0% 2%' }} />
              <Card.Body>
                <Card.Title style={{ textAlign: 'center' }}>
                  <a href="assets/img/layon-mark.png" style={{ color: '#000000' }}>Manoj Shah</a>
                </Card.Title>
                <Card.Text style={{color:"black"}}>Third Vice President</Card.Text>
              </Card.Body>
            </Card>
            
          </Col>
        </Row>
      </Container>
    </section><br></br>
    <Footer/> 
  </div>
  )
}

export default International;
