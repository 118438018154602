import logo from './logo.svg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Router,Routes,Switch } from 'react-router-dom';

import Home from './Components/Home/Home';
import International from './Components/Navbar/About/International';
import Multiple from './Components/Navbar/About/Multiple';
import A320 from './Components/Navbar/Districts/A320';
import B320 from './Components/Navbar/Districts/B320';
import C320 from './Components/Navbar/Districts/C320';
import D320 from './Components/Navbar/Districts/D320';
import E320 from './Components/Navbar/Districts/E320';
import F320 from './Components/Navbar/Districts/F320';
import G320 from './Components/Navbar/Districts/G320';
import Upload from './Components/Navbar/Upload/Upload';
import Contact from './Components/Navbar/Contact/Contact';
import H320 from './Components/Navbar/Districts/H320';


function App() {
  return (
    <div className="App">
   
   <div>
      <BrowserRouter>
        <Routes>
          {/* <Route path="/" element={<Header />} />
          <Route path="/footer" element={<Footer />} /> */}
          <Route path="/" element={<Home />} />
          <Route path="/international" element={<International />} />
          <Route path="/multiple" element={<Multiple />} />
          <Route path="/320a" element={<A320 />} />
          <Route path="/320b" element={<B320 />} />
          <Route path="/320c" element={<C320 />} />
          <Route path="/320d" element={<D320 />} />
          <Route path="/320e" element={<E320 />} />
          <Route path="/320f" element={<F320 />} />
          <Route path="/320g" element={<G320 />} />
          <Route path="/320h" element={<H320 />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/upload" element={<Upload />} />
        </Routes>
      </BrowserRouter>
    </div>
   
    </div>
  );
}

export default App;
