import React from 'react'
import { Carousel } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../../Header/Header';
import TopNavbar from '../TopNavbar'
import Footer from '../../Footer/Footer'
import banner10 from '../img/banner 10.png'
import e320 from '../img/e320.jpg'
import E1 from '../img/E1.jpg'
import e2 from '../img/e2.png'
import e3 from '../img/e3.jpg'

function E320() {
  return (
    <div>
    <Header />
    <TopNavbar/>

    <div className="banner-area">
    <Carousel id="bootcarousel" className="text-light heading-uppercase animate_text" controls indicators={false}>
      {/* <Carousel.Item className="bg-cover img-fluid active">
        <img src={banner10} className="d-block w-100" alt="First slide" />
      </Carousel.Item> */}
      <Carousel.Item className="bg-cover img-fluid"  style={{padding:"30px"}}>
        <img src={e320} className="d-block w-100" alt="Second slide" />
      </Carousel.Item>
    </Carousel>
  </div>
<br></br>
<section id="about-us" className="about-us">
      <Container data-aos="fade-up">
        <div className="section-title">
          <h2>DG TEAM - DISTRICT 320E</h2>
        </div><br/>
        <Row style={{padding:"100px"}}>
          <Col lg={4} md={6} className="d-flex align-items-stretch">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <img src={e2} style={{ width:'200px',height:'200px' }} alt="Yarala Prabhakar Reddy" />
              </div>
              <h4>Yarala Prabhakar Reddy</h4>
              <p style={{color:"black"}}>District Governor<br/><br />Mobile: 9642471444</p>
            </div>
          </Col>
          <Col lg={4} md={6} className="d-flex align-items-stretch">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <img src={e3} style={{ width:'200px',height:'200px' }} alt="CH.Shiva Prasad" />
              </div>
              <h4>Madhusudhan Rao Namburu</h4>
              <p style={{color:"black"}}>First Vice District Governor<br/><br />Mobile: 9440097431</p>
            </div>
          </Col>
          <Col lg={4} md={6} className="d-flex align-items-stretch">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <img src={E1} style={{ width: '200px',height:'200px' }} alt="Yarala Prabhakar Reddy" />
              </div>
              <h4>K V Prasad</h4>
              <p style={{color:"black"}}>Second Vice District Governor<br/></p>
            </div>
          </Col>
        </Row>
{/* 
        <div className="section-title">
          <h2>GALLERY - DISTRICT 320E</h2>
        </div> */}
        {/* <Row>
          <Col lg={4} md={6} className="d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <img src="https://lionsmd320.org/uploads/220705060757.jpg" style={{ width: '50%', cursor: 'zoom-in' }} alt="Installation ceremony" title="Installation ceremony" />
              </div>
              <span>Installation ceremony</span>
            </div>
          </Col>

         
        </Row> */}
      </Container>
    </section><br/>
    <Footer/> 
  </div>
  )
}

export default E320
