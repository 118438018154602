import React from 'react'
import { Carousel } from 'react-bootstrap';
import { Container, Row, Col } from 'react-bootstrap';
import Header from '../../Header/Header';
import TopNavbar from '../TopNavbar'
import Footer from '../../Footer/Footer'
import g320 from '../img/g320.jpg'
import h3 from '../img/h3.jpg'
import h2 from '../img/h2.jpeg'
import h1 from '../img/h1.jpeg'

function H320() {
  return (
    <div>
    <Header />
    <TopNavbar/>

    <div className="banner-area">
    <Carousel id="bootcarousel" className="text-light heading-uppercase animate_text" controls indicators={false}>
      <Carousel.Item className="bg-cover img-fluid" style={{padding:"30px"}}>
        <img src={g320} className="d-block w-100" alt="Second slide" />
      </Carousel.Item>
    </Carousel>
  </div><br/>
  <section id="about-us" className="about-us">
      <Container data-aos="fade-up">
        <div className="section-title">
          <h2>DG TEAM - DISTRICT 320G</h2>
        </div>
        <Row style={{padding:"100px"}}>
          <Col lg={4} md={6} className="d-flex align-items-stretch">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <img src={h2} style={{ width:'200px',height:'200px' }} alt="N Venkateswara Rao" />
              </div>
              <h4>N Manohar Reddy</h4>
              <p style={{color:"black"}}>District Governor<br/><br />Mobile: 9246536882</p>
            </div>
          </Col>
          <Col lg={4} md={6} className="d-flex align-items-stretch">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <img src={h1} style={{ width:'200px',height:'200px' }} alt="Gampa Nageswara Rao" />
              </div>
              <h4>Gampa Nageswara Rao</h4>
              <p style={{color:"black"}}>First Vice District Governor<br/><br/>Mobile: 9849000026</p>
            </div>
          </Col>
          <Col lg={4} md={6} className="d-flex align-items-stretch">
            <div className="icon-box iconbox-blue">
              <div className="icon">
                <img src={h3} style={{ width:'200px',height:'200px' }} alt="N Visweswara Rao" />
              </div>
              <h4>N Visweswara Rao</h4>
              <p style={{color:"black"}}>Second Vice District Governor<br/><br />Mobile: 8639306752</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section><br/>

    <Footer/> 
  </div>
  )
}

export default H320
