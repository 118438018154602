import React, { useEffect, useState } from 'react'
import index from '../Home/index.css';
// import banner1 from './banner/banner1.png'
import banner8 from './banner/banner8.png'
import banner7 from './banner/banner7.png'
import banner6 from './banner/banner6.png'
import banner5 from './banner/banner5.png'
import banner4 from './banner/banner4.png'
import banner3 from './banner/banner3.png'
import banner2 from './banner/banner2.png'
import { Carousel } from 'react-bootstrap';
import { Card } from 'react-bootstrap';
import CardGroup from 'react-bootstrap/CardGroup';
import A320 from './banner/A320.png'
import B320 from './banner/B320.png'
import C320 from './banner/C320.png'
import D320 from './banner/D320.png'
import F320 from './banner/F320.png'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import lionsimg from './banner/lionsimg.jpg'
import lionsimg1 from './banner/lionsimg1.jpg'
import lionsimg2 from './banner/lionsimg2.jpg'
import lionsimg3 from './banner/lionsimg3.jpg'
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import TopNavbar from '../Navbar/TopNavbar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';



function Home() {

    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setIndex((prevIndex) => (prevIndex === 7 ? 0 : prevIndex + 1));
        }, 2000);
        return () => clearInterval(intervalId);
    }, []);

    const cardStyle = {
        borderRadius: '15px',
        overflow: 'hidden',
        transition: 'transform 0.3s ease',
        boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)',
    };

    const cardImageStyle = {
        transition: 'transform 0.3s ease',
    };

    const handleMouseEnter = (event) => {
        event.target.style.transform = 'scale(1.05)';
    };

    const handleMouseLeave = (event) => {
        event.target.style.transform = 'scale(1)';
    };


    return (
        <div>
            
            <TopNavbar />
            <div>
                <Carousel activeIndex={index} onSelect={handleSelect} interval={null}>
                    <Carousel.Item>
                        {/* <img src={banner1} className="d-block w-100" alt="Banner 1" /> */}
                    </Carousel.Item>
                    <Carousel.Item style={{padding:"30px"}}>
                        <img src={banner2} className="d-block w-100" alt="Banner 2" />
                    </Carousel.Item>
                    <Carousel.Item style={{padding:"30px"}}>
                        <img src={banner3} className="d-block w-100" alt="Banner 3" />
                    </Carousel.Item>
                    <Carousel.Item style={{padding:"30px"}}>
                        <img src={banner4} className="d-block w-100" alt="Banner 4" />
                    </Carousel.Item>
                    <Carousel.Item style={{padding:"30px"}}>
                        <img src={banner5} className="d-block w-100" alt="Banner 5" />
                    </Carousel.Item>
                    <Carousel.Item style={{padding:"30px"}}>
                        <img src={banner6} className="d-block w-100" alt="Banner 6" />
                    </Carousel.Item>
                    <Carousel.Item style={{padding:"30px"}}>
                        <img src={banner7} className="d-block w-100" alt="Banner 7" />
                    </Carousel.Item>
                    <Carousel.Item style={{padding:"30px"}}>
                        <img src={banner8} className="d-block w-100" alt="Banner 8" />
                    </Carousel.Item>
                </Carousel>

            </div>
            <br />

            <div>

                <Card className="md-4 " style={{ width: '100%', border: 'none', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)', transition: 'transform 0.3s ease' }}>
                    <Card.Body>
                        <Card.Title style={{ fontSize: '1rem', fontWeight: 'bold' }}>DIVERSITY BRIDGES ALL DIVIDES</Card.Title>
                        <Card.Text style={{ color: '#666666' }} >Creating Harmony Through Services With international President, Dr. Jung-Yul Choi</Card.Text>
                    </Card.Body>
                </Card>

                <Card className=" md-4" style={{ width: '100%', border: 'none', boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.1)', transition: 'transform 0.3s ease' }}>
                    <Card.Body>
                        <Card.Title style={{ fontSize: '1rem', fontWeight: 'bold' }}>Lions Clubs International Multiple District 320</Card.Title>
                        <Card.Text style={{ color: '#666666' }}>
                            Hard work: The importance of giving your all with consistency, vision, and passion. Never allowing a crisis to go unserved. These are the values passed on by a loving mother to her son - 103rd International President Dr. Jung-Yul Choi. As a member of the Busan Jae-IL Lions Club in the Republic of Korea for over 40 years, international president Choi leads by example. His career and life have been defined by the work ethic instilled in him as a child, along with a passion to bridge differences, connect people, and embrace diversity to create harmony for all.
                        </Card.Text>
                    </Card.Body>
                </Card>
            </div>

            <div >
                <p style={{fontSize:'2rem',fontWeight:'bold',color:"black"}}>OUR DISTRICTS</p>
            </div>

            <center >
                <div style={{height:'60%',width:'80%',alignItems:'center',justifyContent:'center'}}>

                    <CardGroup style={{ gap: '2rem' }}>
                        <Row >
                            <Col lg={4}>
                            <Card style={cardStyle} className='A320'>
                                    <Card.Img
                                        variant="top"
                                        src={A320}
                                        style={cardImageStyle}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <Card.Body >
                                        <Card.Title>District 320A</Card.Title>
                                        <Card.Text>Raichur,Bidar,MahabubNagar,<br />RR,<a href='#'><b>READ MORE</b></a></Card.Text>
                                        {/* <a href='#'>READ MORE</a> */}
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={4}>
                                <Card style={cardStyle} className='B320'>
                                    <Card.Img
                                        variant="top"
                                        src={B320}
                                        style={cardImageStyle}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <Card.Body>
                                        <Card.Title>District 320B</Card.Title>
                                        <Card.Text>District 320 B -Ranga Reddy and Hyderabad <a href='#'><b>READ MORE</b></a></Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={4}>
                                <Card style={cardStyle} className='C320'>
                                    <Card.Img
                                        variant="top"
                                        src={C320}
                                        style={cardImageStyle}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <Card.Body>
                                        <Card.Title>District 320C</Card.Title>
                                        <Card.Text>District 320 C -Ranga Reddy and Hyderabad <a href='#'><b>READ MORE</b></a></Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <Card style={cardStyle} className='D320'>
                                    <Card.Img
                                        variant="top"
                                        src={D320}
                                        style={cardImageStyle}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <Card.Body>
                                        <Card.Title>District 320D</Card.Title>
                                        <Card.Text>Adilabad,Nizamabad,Medak,RR and Hyderabad <a href='#'><b>READ MORE</b></a></Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={4}>
                                <Card style={cardStyle}>
                                    <Card.Img
                                        variant="top"
                                        src='/'
                                        style={{backgroundColor:"rgb(90, 132, 172)"}}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <Card.Body style={{backgroundColor:"rgb(90, 132, 172)"}}>
                                        <Card.Title>District 320E</Card.Title>
                                        <Card.Text >District 320 E -Nalagonda and Khammam <a href='#'><b>READ MORE</b></a></Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col lg={4}>
                                <Card style={cardStyle} className='F320'>
                                    <Card.Img
                                        variant="top"
                                        src={F320}
                                        style={cardImageStyle}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <Card.Body>
                                        <Card.Title>District 320F</Card.Title>
                                        <Card.Text>District 320 F -Warangal <a href='#'><b>READ MORE</b></a></Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4}>
                                <Card style={cardStyle} >
                                    <Card.Img
                                        variant="top"
                                        src='/'
                                        style={{backgroundColor:"rgb(90, 132, 172)"}}
                                        onMouseEnter={handleMouseEnter}
                                        onMouseLeave={handleMouseLeave}
                                    />
                                    <Card.Body style={{backgroundColor:"rgb(90, 132, 172)"}}>
                                        <Card.Title >District 320G</Card.Title>
                                        <Card.Text>District 320 G -Adilabad and Karimnagar <a href='#'><b>READ MORE</b></a></Card.Text>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </CardGroup>
                </div>
            </center><br /><br /><br />
            <div>
                <p style={{ fontSize: '2.0rem', fontWeight: 'bold',color:"black" }}>Latest NEWS</p>
            </div><br /><br />
            {/* <div>
                <Carousel nextLabel="" prevLabel="">
                    <Carousel.Item>
                        <CardGroup style={{ gap: '0.2rem' }}>
                            <Row>
                                <Col>
                                    <Card style={{ cardStyle, height: '50%', width: '60%', backgroundColor: 'lightgray', lineHeight: '2rem' }}>
                                        <Card.Img
                                            variant="top"
                                            src={lionsimg3}
                                            style={cardImageStyle}
                                            onMouseEnter={handleMouseEnter}
                                            onMouseLeave={handleMouseLeave}
                                        />
                                        <Card.Body>
                                            <div class="info">
                                                <div class="cats">
                                                    <a href="#">Global Action Team (GAT) Leadership</a><br />
                                                    <a href="#">Clubs Are at the Center of the Action</a>
                                                </div>
                                                <h4>
                                                    <a href="">Friendly bachelor entrance</a>
                                                </h4>
                                                <p>
                                                    The Global Action Team (GAT) puts the entire  Lions network to work for your club. And teamwork is the key—it brings the entire GLT, GMT and GST together to support your club. It’s a bottoms-up, whole-club approach that can energize every facet of your club. Help is all around you.
                                                </p>
                                                <div class="meta">

                                                    <a href="#">Read More</a>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ cardStyle, height: '50%', width: '60%', backgroundColor: 'lightgray', lineHeight: '2rem' }}>
                                        <Card.Img
                                            variant="top"
                                            src={lionsimg}
                                            style={cardImageStyle}
                                            onMouseEnter={handleMouseEnter}
                                            onMouseLeave={handleMouseLeave}
                                        />
                                        <Card.Body>
                                            <div class="info">
                                                <div class="cats">
                                                    <a href="#">Where there’s a need, there’s a Leo</a>
                                                    <a href="#">“Leadership, Experience, Opportunity.”</a>
                                                </div>
                                                <h4>
                                                    <a href="#">That’s what makes a Leo. As the youngest members of Lions Clubs International</a>
                                                </h4>
                                                <p>
                                                    Leos embody the best qualities of our incredible organization. They are devoted young people who realize the power of action. Together, Leos and Lions form a powerful partnership — one of mutual respect where Lions learn
                                                </p>
                                                <div class="meta">

                                                    <a href="#">Read More</a>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>

                                <Col>
                                    <Card style={{ cardStyle, height: '50%', width: '60%', backgroundColor: 'lightgray', lineHeight: '2rem' }}>
                                        <Card.Img
                                            variant="top"
                                            src={lionsimg1}
                                            style={cardImageStyle}
                                            onMouseEnter={handleMouseEnter}
                                            onMouseLeave={handleMouseLeave}
                                        />
                                        <Card.Body>
                                            <div class="info">
                                                <div class="cats">
                                                    <a href="#">The Foundation of Service</a>
                                                    <a href="#">Impacting lives through humanitarian service projects and grants.</a>
                                                </div>
                                                <h4>
                                                    <a href="#">Lions Clubs International Foundation</a>
                                                </h4>
                                                <p>
                                                    The story of LCIF is epic—filled with unlimited compassion and tireless support of Lions service. Founded in 1968, the mission of LCIF is "To support the efforts of Lions clubs  and partners in serving communities locally   and globally, giving hope and impacting lives
                                                </p>
                                                <div class="meta">

                                                    <a href="#">Read More</a>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ cardStyle, height: '50%', width: '60%', backgroundColor: 'lightgray', lineHeight: '2rem' }}>
                                        <Card.Img
                                            variant="top"
                                            src={lionsimg2}
                                            style={cardImageStyle}
                                            onMouseEnter={handleMouseEnter}
                                            onMouseLeave={handleMouseLeave}
                                        />
                                        <Card.Body>
                                            <div class="info">
                                                <div class="cats">
                                                    <a href="#">The History of Lions Quest</a>
                                                    <a href="#">The ideas behind Lions Quest date back to 1975</a>
                                                </div>
                                                <h4>
                                                    <a href="#">LCIF</a>
                                                </h4>
                                                <p>The ideas behind Lions Quest date back to 1975, when a teenager named Rick Little set out on a “quest” to help other young people develop the skills and strength of character needed to succeed as adults. Rick conducted a survey of over 2,000 high school students
                                                </p>
                                                <div class="meta">

                                                    <a href="#">Read More</a>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </CardGroup>
                    </Carousel.Item>
                </Carousel>
            </div> */}
            <div>
                <Carousel controls indicators id="bootcarousel" >
                    <Carousel.Item>
                        <CardGroup style={{ gap: '0.2rem', marginLeft: '10%' }}>
                            <Row>
                                <Col>
                                    <Card style={{ height: '40%', width: '60%', backgroundColor: 'lightgray', lineHeight: '2rem' }}>
                                        <Card.Img
                                            variant="top"
                                            src={lionsimg3}
                                            style={{ height: '100%', objectFit: 'cover', }}
                                        />
                                        <Card.Body>
                                            <div className="info">
                                                <div className="cats">
                                                    <a href="#">.Global Action Team (GAT) Leadership</a><br />
                                                    <a href="#">.Clubs Are at the Center of the Action</a>
                                                </div>
                                                <h6>
                                                    <a href="">Friendly bachelor entrance</a>
                                                </h6>
                                                <p style={{color:"black",textAlign:"justify"}}>
                                                    The Global Action Team (GAT) puts the entire Lions network to work for your club. And teamwork is the key—it brings the entire GLT, GMT, and GST together to support your club. It’s a bottoms-up, whole-club approach that can energize every facet of your club. Help is all around you.
                                                </p>
                                                <div className="meta">
                                                    <a href="#">Read More</a>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ height: '40%', width: '60%', backgroundColor: 'lightgray', lineHeight: '2rem' }}>
                                        <Card.Img
                                            variant="top"
                                            src={lionsimg}
                                            style={{ height: '100%', objectFit: 'cover' }}
                                        />
                                        <Card.Body>
                                            <div className="info">
                                                <div className="cats">
                                                    <a href="#">.Where there’s a need, there’s a Leo</a><br />
                                                    <a href="#">“Leadership, Experience, Opportunity.”</a>
                                                </div>
                                                <h6>
                                                    <a href="#">That’s what makes a Leo. As the youngest members of Lions Clubs International</a>
                                                </h6>
                                                <p style={{color:"black",textAlign:"justify"}}>
                                                    Leos embody the best qualities of our incredible organization. They are devoted young people who realize the power of action. Together, Leos and Lions form a powerful partnership — one of mutual respect where Lions learn

                                                </p>
                                                <div className="meta">
                                                    <a href="#">Read More</a>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </CardGroup>
                    </Carousel.Item>
                    <Carousel.Item>
                        <CardGroup style={{ gap: '0.2rem', marginLeft: '10%' }}>
                            <Row>
                                <Col>
                                    <Card style={{ height: '40%', width: '60%', backgroundColor: 'lightgray', lineHeight: '2rem' }}>
                                        <Card.Img
                                            variant="top"
                                            src={lionsimg1}
                                            style={{ height: '100%', objectFit: 'cover' }}
                                        />
                                        <Card.Body>
                                            <div className="info">
                                                <div className='cats'>
                                                    <a href="#" className='none'>.The Foundation of Service</a><br />
                                                    <a href="#"className='none'>.Impacting lives through humanitarian service projects and grants.</a>
                                                </div>
                                                <h6>
                                                    <a href="#">Lions Clubs International Foundation</a>
                                                </h6>
                                                <p style={{color:"black",textAlign:"justify"}}>
                                                    The story of LCIF is epic—filled with unlimited compassion and tireless support of Lions service. Founded in 1968, the mission of LCIF is "To support the efforts of Lions clubs and partners in serving communities locally and globally, giving hope and impacting lives
                                                    {/* through humanitarian service projects and grants. */}
                                                </p>
                                                <div className="meta">
                                                    <a href="#">Read More</a>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                <Col>
                                    <Card style={{ height: '40%', width: '60%', backgroundColor: 'lightgray', lineHeight: '2rem' }}>
                                        <Card.Img
                                            variant="top"
                                            src={lionsimg2}
                                            style={{ height: '100%', objectFit: 'cover' }}
                                        />
                                        <Card.Body>
                                            <div className="info">
                                                <div className="cats">
                                                    <a href="#">.The History of Lions Quest</a><br />
                                                    <a href="#">.The ideas behind Lions Quest date back to 1975</a>
                                                </div>
                                                <h6>
                                                    <a href="#">LCIF</a>
                                                </h6>
                                                <p style={{color:"black",textAlign:"justify"}}>
                                                    The ideas behind Lions Quest date back to 1975, when a teenager named Rick Little set out on a “quest” to help other young people develop the skills and strength of character needed to succeed as adults. Rick conducted a survey of over 2,000 high school students
                                                    {/* to determine the issues that concerned them most and consulted experienced teachers who suggested including a teacher workshop which would serve as a classroom model. The program was so successful at the high school level that soon middle and elementary schools were asking for age-appropriate positive youth development programs. Little eventually founded and served as chief executive officer of the International Youth Foundation. */}
                                                </p>
                                                <div className="meta">
                                                    <a href="#">Read More</a>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col>
                                {/* <Col>
                                    <Card style={{ height: '50%', width: '60%', backgroundColor: 'lightgray', lineHeight: '2rem' }}>
                                        <Card.Img
                                            variant="top"
                                            src={lionsimg2}
                                            style={{ height: '100%', objectFit: 'cover' }}
                                        />
                                        <Card.Body>
                                            <div className="info">
                                                <div className="cats">
                                                    <a href="#">The History of Lions Quest</a><br />
                                                    <a href="#">The ideas behind Lions Quest date back to 1975</a>
                                                </div>
                                                <h4>
                                                    <a href="#">LCIF</a>
                                                </h4>
                                                <p>The ideas behind Lions Quest date back to 1975, when a teenager named Rick Little set out on a “quest” to help other young people develop the skills and strength of character needed to succeed as adults. Rick conducted a survey of over 2,000 high school students
                                                </p>
                                                <div className="meta">
                                                    <a href="#">Read More</a>
                                                </div>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </Col> */}
                            </Row>
                        </CardGroup>
                    </Carousel.Item>
                </Carousel>
            </div>
            <Footer />

          
        </div>
    )
}

export default Home
